import { object, array, string, date } from "yup";

export const validationSchema = object({
  mkto_qs_appender_parameters_token: string().required("SETTINGS.MKTO.QSAPPEND.TOAST.ERROR.REQUIRED"),
  mkto_qs_appender_url_parameters: array()
    .of(
      object().shape({
        label: string(),
        value: string()
      })
    )
    .min(1, "SETTINGS.MKTO.QSAPPEND.TOAST.ERROR.MIN1URL")
    .required("SETTINGS.MKTO.QSAPPEND.TOAST.ERROR.REQUIRED"),
  mkto_qs_appender_excluded_domains: array().of(
    object().shape({
      label: string(),
      value: string()
    })
  ),
  mkto_qs_appender_update_non_templated: string().required("SETTINGS.MKTO.QSAPPEND.TOAST.ERROR.REQUIRED"),
  mkto_qs_appender_start_date: date().required("SETTINGS.MKTO.QSAPPEND.TOAST.ERROR.REQUIRED")
});
