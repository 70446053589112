import { useEffect, useState } from "react";
import { useInsJSFilesRequest } from "../core/InsJSFilesProvider";
import InsentricJsForm from "./InsentricJsForm";

const InsentricJsModalWrapper = () => {
  const { itemIdForUpdate, trackerFiles } = useInsJSFilesRequest();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fileToUpdate, setFileToUpdate] = useState<object>({});

  useEffect(() => {
    if (itemIdForUpdate && trackerFiles.length > 0) {
      const temp = trackerFiles.find(
        (file: any) => file.id === itemIdForUpdate
      );

      const tempValidDomains = temp.obfuscator_config.domain_lock
        ? temp.obfuscator_config.domain_lock.map((domain: any) => ({
            value: domain,
            label: domain
          }))
        : [];

      const tempReservedStrings = temp.obfuscator_config.reserved_strings
        ? temp.obfuscator_config.reserved_strings.map((string: any) => ({
            value: string,
            label: string
          }))
        : [];

      const tempRefferals = temp.config.refferals
        ? temp.config.refferals.map((refferal: any) => ({
            domain: refferal.display,
            trafficMedium: refferal.medium,
            displayName: refferal.host
          }))
        : [];

      setFileToUpdate({
        ...temp,
        valid_domains: tempValidDomains,
        refferals: tempRefferals,
        reservedstrings: tempReservedStrings
      });

      setIsLoading(false);
    }
  }, [itemIdForUpdate, trackerFiles]);

  if (!itemIdForUpdate) {
    return <InsentricJsForm isFileLoading={isLoading} fileToUpdate={{}} />;
  }

  if (!isLoading && fileToUpdate) {
    return (
      <InsentricJsForm isFileLoading={isLoading} fileToUpdate={fileToUpdate} />
    );
  }

  return null;
};

export { InsentricJsModalWrapper };
