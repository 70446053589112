import React, { useEffect, useRef, useCallback, ReactNode } from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import raf from 'rc-util/lib/raf';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';

interface HandleTooltipProps {
  value: any;
  children: React.ReactNode;
  visible: boolean;
  tipFormatter?: (val: any) => React.ReactNode;
}

const HandleTooltip: React.FC<HandleTooltipProps> = ({ value, children, visible, tipFormatter = (val) => `${val} %`, ...restProps }) => {
  const rafRef = useRef<number | null>(null);

  const cancelKeepAlign = useCallback(() => {
    if (rafRef.current !== null) {
      raf.cancel(rafRef.current);
    }
  }, []);

  const keepAlign = useCallback(() => {
    rafRef.current = raf(() => {
      // Custom logic to manually trigger re-render or alignment adjustments
      // if needed
    });
  }, []);

  useEffect(() => {
    if (visible) {
      keepAlign();
    } else {
      cancelKeepAlign();
    }

    return cancelKeepAlign;
  }, [value, visible, keepAlign, cancelKeepAlign]);

  return (
    <Tooltip
      placement="top"
      overlay={tipFormatter(value)}
      overlayInnerStyle={{ minHeight: 'auto' }}
      visible={visible}
      {...restProps}
    >
      {children as React.ReactElement}
    </Tooltip>
  );
};

HandleTooltip.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  tipFormatter: PropTypes.func,
};

export const handleRender = (node: React.ReactNode, props: HandleTooltipProps) => {
  return (
    <HandleTooltip value={props.value} visible={props.visible}>
      {node}
    </HandleTooltip>
  );
};
