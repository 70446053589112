import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";
import { useIntl } from "react-intl";

const Error500: FC = () => {
  const intl = useIntl();

  useEffect(() => {
    document.title = "500 - Internal Server Error";
  }, []);

  return (
    <>
      <div className="text-center mb-11">
        {/* begin::Logo */}
        <Link to="/app/home" className="mb-12 d-none d-sm-none d-lg-block">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/default-dark.svg")}
            className="theme-dark-show h-45px"
          />
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/default.svg")}
            className="theme-light-show h-45px"
          />
        </Link>
        {/* end::Logo */}
      </div>

      {/* begin::Illustration */}
      <div className="mb-11">
        <img
          src={toAbsoluteUrl("/media/auth/error-3.svg")}
          className="mw-100 mh-300px theme-light-show"
          alt=""
        />
        <img
          src={toAbsoluteUrl("/media/auth/error-3.svg")}
          className="mw-100 mh-300px theme-dark-show"
          alt=""
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Title */}
      <h1 className="fw-bolder fs-2qx text-gray-900 mb-4">
        {intl.formatMessage({ id: "ERRORPAGE.500.TITLE" })}
      </h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className="fw-semibold fs-6 text-gray-500 mb-7">
        {intl.formatMessage({ id: "ERRORPAGE.500.DESCRIPTION" })}
      </div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className="mb-0">
        <Link to="/app/home" className="btn btn-sm btn-primary">
          {intl.formatMessage({ id: "ERRORPAGE.BUTTON" })}
        </Link>
      </div>
      {/* end::Link */}
    </>
  );
};

export { Error500 };
