/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { FC } from "react";
import { useLang, setLanguage } from "../../../localization/i18n";
import { languages } from "../../../localization/languages/languages";


const Languages: FC = () => {
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang);
  return (
    <>
      <button
        className="btn btn-flex btn-link btn-color-gray-700 btn-active-color-primary rotate fs-base"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="top-start"
        data-kt-menu-flip="top"
      >
        <img
          data-kt-element="current-lang-flag"
          className="w-20px h-20px rounded me-3"
          src={currentLanguage?.flag}
          alt=""
        />

        <span data-kt-element="current-lang-name" className="me-1">
          {currentLanguage?.name}{" "}
        </span>

        <span className="d-flex flex-center rotate-180">
          <i className="ki-duotone ki-down fs-5 text-muted m-0"></i>{" "}
        </span>
      </button>

      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4 fs-7"
        data-kt-menu="true"
        id="kt_auth_lang_menu"
      >
        {languages.map((l) => (
          <div
            className="menu-item px-3"
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang);
            }}
          >
            <a
              href="#"
              className={clsx("menu-link d-flex px-5", {
                active: l.lang === currentLanguage?.lang
              })}
              data-kt-lang={l.name}
            >
              <span className="symbol symbol-20px me-4">
                <img
                  data-kt-element="lang-flag"
                  className="rounded-1"
                  src={l.flag}
                  alt={l.name}
                />
              </span>
              <span data-kt-element="lang-name">{l.name}</span>
            </a>
          </div>
        ))}

      </div>
    </>
  );
};

export { Languages };
