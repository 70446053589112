import { FC } from "react";
import { useLayout } from "../../layout/core";
import { KTIcon } from "..";
import { Tooltip } from "react-tooltip";

type TooltipProps = {
  tooltipText?: string;
  tooltipHTML?: string;
  icon?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  trigger?: string;
  variant?: 'dark' | 'light' | 'warning' | 'info';
};

const CustomTooltip: FC<TooltipProps> = ({
  tooltipText,
  tooltipHTML,
  icon = "information-4",
  placement = "right",
  trigger,
  variant = "dark"
}) => {
  const { config } = useLayout();
  const { app } = config;

  const closeEvent = trigger === 'click' ? {'click': true} : {'mouseleave': true}
  const openEvent = trigger === 'click' ? {'click': true} : {'mouseenter': true}
  const tooltipID = `tooltip-${10000 + Math.floor(Math.random() * 10000)}`

  return (
    <div style={{display:"inline-block", marginLeft: 8}}>
      {icon && app?.sidebar?.default?.menu?.iconType === "svg" && (
        <>
          <span data-tooltip-id={tooltipID} style={{cursor: "pointer"}}>
            <KTIcon iconName={icon} className="fs-2" />
          </span>
          <Tooltip
            id={tooltipID}
            key={tooltipID}
            content={tooltipText}
            html={tooltipHTML}
            place={placement}
            variant={variant}
            style={{zIndex:100}}
            closeEvents={closeEvent}
            openEvents={openEvent}
          />
        </>
      )}
    </div>
  );
};

export default CustomTooltip;
